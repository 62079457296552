/* eslint-disable max-len */
import React, { Component } from 'react'

export default class Icon extends Component {
  static defaultProps = {
    color: '#666',
    width: 16,
    height: 16,
    styles: {},
    viewBox: '0 0 16 16',
    className: ''
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={this.props.styles}
        width={this.props.width}
        height={this.props.height}
        viewBox={this.props.viewBox}
        className={`icon ${this.props.className}`}
        preserveAspectRatio="xMinYMin meet">
        {this.renderIcon()}
      </svg>
    )
  }

  renderIcon() {
    const { name, color } = this.props

    switch(name) {
      case 'upload': {
        return (
          <path fill={color} d="M3.4375,13.9639007 C1.486375,13.6461296 0,11.9985269 0,10.0137931 C0,8.43895727 0.9356875,7.07641379 2.2969375,6.4221793 C2.146375,6.09172412 2.0625,5.72588689 2.0625,5.34068968 C2.0625,3.86599174 3.29381251,2.67034483 4.81250001,2.67034483 C5.6216875,2.67034483 6.34975,3.01014619 6.853,3.55022343 C7.9165625,1.44732688 10.143375,0 12.71875,0 C16.2889375,0 19.190875,2.78183174 19.248625,6.23592277 C20.851875,6.78534621 22,8.26939033 22,10.0137931 C22,11.9985269 20.513625,13.6461296 18.5625,13.9639007 L17.875,14.0193103 L4.12500001,14.0193103 L3.4375,13.9639007 Z M14,13.0333938 L17.8840749,13.0333938 L18.4889204,13.0333939 C19.8064731,12.8231043 21.1147541,11.2237921 21.1147541,9.94642893 C21.1147541,8.84419865 20.382932,7.85655344 19.2933911,7.49001138 L18.3264448,7.23107201 L18.3264445,6.29330673 C18.282071,3.64678212 15.5411522,1 12.7640515,1 C10.7959145,1 9.04995554,2.06084019 8.01450605,3.60420616 L7.10024804,4.82359598 L6.02485769,4.07635729 C5.76271249,3.79900933 5.30451875,3.60420623 4.91334896,3.60420627 C3.77397317,3.60420639 3.04800938,4.67095813 3.04800938,5.38906802 C3.04800938,5.5733156 3.08692153,5.7504016 3.16406321,5.91641978 L3.49487704,6.78084302 C1.85122473,7.3480845 1,8.9483669 1,9.94642893 C1,11.2237921 2.45642037,12.7866453 3.77397305,12.9969349 L4.23067915,13.0333938 L8,13.0333938 L8,14 L14,14 L14,13.0333938 L14,13.0333938 Z M7,9.5 L8.9954834,9.5 L8.9954834,14 L12.9868164,14 L12.9868164,9.5 L15,9.5 L11,5 L7,9.5 Z"/>
        )
      }

      case 'source': {
        return (
          <path fill={color} d="M0,6 L0,22 L16,22 L16,6 L11.5,6 L11.5,7 L15,7 L15,21 L1,21 L1,7 L4.5,7 L4.5,6 L0,6 Z M8,0 L3,3 L3,4 L7.5015625,1.25 L7.5015626,14 L8.5,14 L8.5,1.25 L13,4 L13,3 L8,0 Z"/>
        )
      }

      case 'transparency': {
        return (
          <path fill={color} d="M92 2h-44c-1.104 0-2 0.9-2 2v42h-42c-1.104 0-2 0.9-2 2v44c0 1.1 0.896 2 2 2h44c1.104 0 2-0.9 2-2v-42h42c1.1 0 2-0.9 2-2v-44c0-1.1-0.9-2-2-2z"/>
        )
      }

      case 'prev': {
        return (
          <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
            <path fill={color} d="M0,11 C0,4.92486745 4.92486745,0 11,0 C17.0751325,0 22,4.92486745 22,11 C22,17.0751325 17.0751325,22 11,22 C4.92486745,22 0,17.0751325 0,11 Z M21,11 C21,5.47715223 16.5228478,1 11,1 C5.47715223,1 1,5.47715223 1,11 C1,16.5228478 5.47715223,21 11,21 C16.5228478,21 21,16.5228478 21,11 Z M9.35456438,11.0794381 L13.2436517,7.19035081 L11.8232233,5.76992245 L6.51992245,11.0732233 L12.1767767,16.7300776 L13.5909903,15.315864 L9.35456438,11.0794381 Z"/>
          </g>
        )
      }

      case 'next': {
        return (
          <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
            <path fill={color} d="M0,11 C0,4.92486745 4.92486745,0 11,0 C17.0751325,0 22,4.92486745 22,11 C22,17.0751325 17.0751325,22 11,22 C4.92486745,22 0,17.0751325 0,11 Z M21,11 C21,5.47715223 16.5228478,1 11,1 C5.47715223,1 1,5.47715223 1,11 C1,16.5228478 5.47715223,21 11,21 C16.5228478,21 21,16.5228478 21,11 Z M12.1454356,11.4205619 L8.25634833,15.3096492 L9.6767767,16.7300776 L14.9800776,11.4267767 L9.3232233,5.76992245 L7.90900974,7.18413601 L12.1454356,11.4205619 Z"/>
          </g>
        )
      }

      default: {
        return null
      }
    }
  }
}
