import Uppy from '@uppy/core';
import AwsS3 from '@uppy/aws-s3';
import Dashboard from '@uppy/dashboard';
import axios from 'axios';
import validUrl from 'valid-url';
import { isNaN, parseInt } from 'lodash';
import React, { useEffect } from 'react';
import Icon from './Icon';
import bemify from '../lib/bemify';

const BEM = bemify('controls');

export function Controls(props) {
  const refsOcrOption = React.useRef(null);
  const refsPsdOption = React.useRef(null);
  const refsExifOption = React.useRef(null);
  const refsMultimediaOption = React.useRef(null);
  const refsChecksumOption = React.useRef(null);
  const refsWebpageOption = React.useRef(null);
  const refsRawOption = React.useRef(null);
  const refsSketchOption = React.useRef(null);
  const refsWidthOption = React.useRef(null);
  const refsHeightOption = React.useRef(null);
  const refsPagesRange = React.useRef(null);

  useEffect(() => {
    const uppy = new Uppy({
      autoProceed: true,
      restrictions: {
        maxFileSize: 10485760, // 10mb
        maxNumberOfFiles: 1,
        minNumberOfFiles: 1,
      },
    });
    uppy.use(Dashboard, {
      trigger: '#uppy-trigger',
    });
    uppy.use(AwsS3, {
      limit: 1,
      timeout: 30 * 1000,
      async getUploadParameters(file) {
        const response = await axios.post('/api/presign-upload', {
          name: file.name,
          type: file.type,
          size: file.size
        });
        return {
          method: response.data.method,
          url: response.data.url,
          fields: response.data.fields,
          headers: response.data.headers,
        };
      },
    });

    uppy.on('complete', (result) => {
      if (result.successful.length === 1) {
        props.updateFileUrl(result.successful[0].uploadURL);
      }
    });
  }, []);

  const renderUploadButton = () => {
    return (
      <div className={BEM('&header')}>
        <strong className={BEM('&title')}>
          Enter a file url or upload a file
        </strong>
        <div className={BEM('&url-input-wrapper')}>
          <input
            type="text"
            value={props.fileUrl}
            onChange={addFileUrl}
            className={BEM('&url-input')}
            placeholder="http://..."
          />
          <button
            title="Upload a file"
            id="uppy-trigger"
            className="btn btn--icon-only"
          >
            <span>
              <Icon name="upload" width={22} height={15} viewBox="0 0 22 15" />
            </span>
          </button>
        </div>
      </div>
    );
  };

  const renderMetadataOptions = () => {
    const { metadata } = props.previewOptions;

    return (
      <div className={BEM('&metadata')}>
        <strong className={BEM('&title')}>Metadata</strong>
        <div className={BEM('&row')}>
          <label className={BEM('&label')}>
            <input
              ref={refsOcrOption}
              type="checkbox"
              value="ocr"
              onChange={onMetadataChange}
              className={BEM('&checkbox')}
              checked={metadata.includes('ocr')}
            />
            <span className={BEM('&label-text')}>OCR</span>
          </label>
          <label className={BEM('&label')}>
            <input
              ref={refsPsdOption}
              type="checkbox"
              value="psd"
              onChange={onMetadataChange}
              className={BEM('&checkbox')}
              checked={metadata.includes('psd')}
            />
            <span className={BEM('&label-text')}>PSD Layers</span>
          </label>
        </div>
        <div className={BEM('&row')}>
          <label className={BEM('&label')}>
            <input
              ref={refsExifOption}
              type="checkbox"
              value="exif"
              onChange={onMetadataChange}
              className={BEM('&checkbox')}
              checked={metadata.includes('exif')}
            />
            <span className={BEM('&label-text')}>EXIF</span>
          </label>
          <label className={BEM('&label')}>
            <input
              ref={refsMultimediaOption}
              type="checkbox"
              value="multimedia"
              onChange={onMetadataChange}
              className={BEM('&checkbox')}
              checked={metadata.includes('multimedia')}
            />
            <span className={BEM('&label-text')}>Multimedia</span>
          </label>
        </div>
        <div className={BEM('&row')}>
          <label className={BEM('&label')}>
            <input
              ref={refsChecksumOption}
              type="checkbox"
              value="checksum"
              onChange={onMetadataChange}
              className={BEM('&checkbox')}
              checked={metadata.includes('checksum')}
            />
            <span className={BEM('&label-text')}>Checksum</span>
          </label>
          <label className={BEM('&label')}>
            <input
              ref={refsWebpageOption}
              type="checkbox"
              value="webpage"
              onChange={onMetadataChange}
              className={BEM('&checkbox')}
              checked={metadata.includes('webpage')}
            />
            <span className={BEM('&label-text')}>Webpage</span>
          </label>
        </div>
        <div className={BEM('&row')}>
          <label className={BEM('&label')}>
            <input
              ref={refsRawOption}
              type="checkbox"
              value="raw"
              onChange={onMetadataChange}
              className={BEM('&checkbox')}
              checked={metadata.includes('raw')}
            />
            <span className={BEM('&label-text')}>RAW</span>
          </label>
          <label className={BEM('&label')}>
            <input
              ref={refsSketchOption}
              type="checkbox"
              value="sketch"
              onChange={onMetadataChange}
              className={BEM('&checkbox')}
              checked={metadata.includes('sketch')}
            />
            <span className={BEM('&label-text')}>Sketch</span>
          </label>
        </div>
      </div>
    );
  };

  const renderFormatOptions = () => {
    const { sandboxId, previewOptions } = props;
    const { format } = previewOptions;

    return (
      <div className={BEM('&formats')}>
        <strong className={BEM('&title')}>Output Format</strong>
        <div className={BEM('&row')}>
          <label className={BEM('&label')}>
            <input
              type="radio"
              name={`${sandboxId}-format`}
              value="png"
              checked={format === 'png'}
              onChange={onFormatChange}
              className={BEM('&radio')}
            />
            <span className={BEM('&label-text')}>.png</span>
          </label>
          <label className={BEM('&label')}>
            <input
              type="radio"
              name={`${sandboxId}-format`}
              value="webp"
              checked={format === 'webp'}
              onChange={onFormatChange}
              className={BEM('&radio')}
            />
            <span className={BEM('&label-text')}>.webp</span>
          </label>
        </div>
        <div className={BEM('&row')}>
          <label className={BEM('&label')}>
            <input
              type="radio"
              name={`${sandboxId}-format`}
              value="jpeg"
              checked={format === 'jpeg'}
              onChange={onFormatChange}
              className={BEM('&radio')}
            />
            <span className={BEM('&label-text')}>.jpeg</span>
          </label>
          <label className={BEM('&label')}>
            <input
              type="radio"
              name={`${sandboxId}-format`}
              value="jpg"
              checked={format === 'jpg'}
              onChange={onFormatChange}
              className={BEM('&radio')}
            />
            <span className={BEM('&label-text')}>.jpg</span>
          </label>
        </div>
      </div>
    );
  };

  const renderDimensionsOptions = () => {
    const { size } = props.previewOptions;

    return (
      <div className={BEM('&dimensions')}>
        <strong className={BEM('&title')}>Preview Dimensions</strong>
        <input
          ref={refsWidthOption}
          type="text"
          value={size.width ? size.width.toString() : ''}
          onChange={onSizeChange}
          className={BEM('&textfield')}
          placeholder="width"
        />
        <span className={BEM('&textfield-divider')} />
        <input
          ref={refsHeightOption}
          type="text"
          value={size.height ? size.height.toString() : ''}
          onChange={onSizeChange}
          className={BEM('&textfield')}
          placeholder="height"
        />
      </div>
    );
  };

  const renderPagesOptions = () => {
    const { sandboxId, previewOptions } = props;
    const { pages } = previewOptions;

    return (
      <div className={BEM('&pages')}>
        <strong className={BEM('&title')}>Pages</strong>
        <div className={BEM('&row')}>
          <label className={BEM('&label')}>
            <input
              type="radio"
              name={`${sandboxId}-pages`}
              value="all"
              checked={pages === 'all'}
              onChange={onPagesChange}
              className={BEM('&radio')}
            />
            <span className={BEM('&label-text')}>All</span>
          </label>
          <label className={BEM('&label')}>
            <input
              type="radio"
              name={`${sandboxId}-pages`}
              value={pages === 'all' ? '1' : pages}
              checked={pages !== 'all'}
              onChange={onPagesChange}
              className={BEM('&radio')}
            />
            <input
              ref={refsPagesRange}
              type="text"
              value={pages !== 'all' ? pages : ''}
              onChange={onPagesChange}
              className={BEM('&textfield')}
              data-value="pages-range"
              placeholder="e.g. 5, 3-7"
            />
          </label>
        </div>
      </div>
    );
  };

  const renderGenerateButton = () => {
    return (
      <div className={BEM('&footer')}>
        <button
          onClick={props.generateFilePreview}
          className="btn"
          data-action="generatePreview"
        >
          Generate preview
        </button>
      </div>
    );
  };

  const addFileUrl = (event) => {
    const { value } = event.target;

    if (validUrl.isWebUri(value)) {
      props.updateFileUrl(value);
    }
  };

  const onMetadataChange = () => {
    const options = [
      refsOcrOption,
      refsPsdOption,
      refsExifOption,
      refsMultimediaOption,
      refsChecksumOption,
      refsWebpageOption,
      refsRawOption,
      refsSketchOption,
    ];

    const metadata = options
      .filter((option) => option.current.checked)
      .map((option) => option.current.value);

    props.updateOptions({ metadata });
  };

  const onFormatChange = (event) => {
    props.updateOptions({ format: event.target.value });
  };

  const onSizeChange = () => {
    const width = parseInt(refsWidthOption.current.value);
    const height = parseInt(refsHeightOption.current.value);

    props.updateOptions({
      size: {
        width: isNaN(width) ? null : width,
        height: isNaN(height) ? null : height,
      },
    });
  };

  const onPagesChange = (event) => {
    const { value } = event.target;

    if (value !== 'all') {
      refsPagesRange.current.focus();
    }

    props.updateOptions({ pages: value });
  };

  return (
    <div className={BEM()}>
      {renderUploadButton()}
      {renderMetadataOptions()}
      {renderFormatOptions()}
      {renderDimensionsOptions()}
      {renderPagesOptions()}
      {renderGenerateButton()}
    </div>
  );
}
