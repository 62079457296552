import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { map, size, clone, first, isEmpty, flatten, compact } from 'lodash';

import { Preview } from '../components/Preview';
import { Snippet } from '../components/Snippet';
import { Controls } from '../components/Controls';

export function Sandbox(props) {
  const [fileUrl, setFileUrl] = useState(props.fileUrl || '');
  const [thumbnails, setThumbnails] = useState([]);
  const [previewData, setPreviewData] = useState({});
  const [snippetType, setSnippetType] = useState('result');
  const [previewColor, setPreviewColor] = useState('transparent');
  const [snippetLanguage, setSnippetLanguage] = useState('node');
  const [currentThumbnail, setCurrentThumbnail] = useState(0);
  const [isGeneratingPreview, setIsGeneratingPreview] = useState(false);
  const [copyToClipboardText, setCopyToClipboardText] =
    useState('Copy to Clipboard');
  const [previewOptions, setPreviewOptions] = useState({
    size: props.previewOptions.size,
    pages: props.previewOptions.pages,
    format: props.previewOptions.format,
    metadata: props.previewOptions.metadata,

    uploader: {
      public: true,

      headers: {
        'Cache-Control': 'max-age=315360000, no-transform, private',
      },
    },
  });

  let previewInterval = null;

  const updateOptions = (options) => {
    setPreviewOptions((val) => {
      return { ...val, ...options };
    });
  };

  const updateFileUrl = (fileUrl) => {
    setFileUrl(fileUrl);
  };

  const generateFilePreview = async () => {
    const options = clone(previewOptions, true);

    clearInterval(previewInterval);

    if (!options.size.width || !options.size.height) {
      delete options.size;
    }

    setIsGeneratingPreview(true);

    try {
      const generateResponse = await axios.post(
        '/api/generate',
        {
          url: fileUrl,
          ...options,
        },
        {
          params: {
            token: props.token,
          },
        }
      );

      previewInterval = setInterval(async () => {
        const retrieveResult = await axios.get(
          `/api/retrieve/${generateResponse.data.id}`,
          {
            params: {
              token: props.token,
            },
          }
        );
        if (
          retrieveResult.data.status !== 'pending' &&
          retrieveResult.data.status !== 'started'
        ) {
          clearInterval(previewInterval);

          setThumbnails(getThumbnails(retrieveResult.data));
          setPreviewData(retrieveResult.data);
          setIsGeneratingPreview(false);
        }
      }, 5000);
    } catch (err) {
      clearInterval(previewInterval);

      setThumbnails([]);
      setPreviewData(err.response ? err.response.data : { error: err.message });
      setIsGeneratingPreview(false);
    }
  };

  const getThumbnails = (result) => {
    const preview = first(result.thumbnails) || {};
    const original = result.original_file || {};

    let thumbnails = [];

    if (
      original.metadata &&
      original.metadata.psd &&
      original.metadata.psd.layers &&
      !isEmpty(original.metadata.psd.layers)
    ) {
      thumbnails = compact(
        flatten([
          preview.url,
          map(original.metadata.psd.layers, (layer) => layer.url),
        ])
      );
    } else if (
      original.metadata &&
      original.metadata.webpage &&
      !isEmpty(original.metadata.webpage.images)
    ) {
      thumbnails = compact(
        flatten([
          preview.url,
          map(original.metadata.webpage.images, (image) => image.src),
        ])
      );
    } else if (!isEmpty(result.thumbnails)) {
      thumbnails = map(result.thumbnails, (thumb) => thumb.url);
    } else {
      thumbnails = [result.preview ? result.preview.url : ''];
    }

    return thumbnails;
  };

  const updatePreviewBackground = (color) => {
    setPreviewColor(color);
  };

  const updateSnippetType = (type) => {
    setSnippetType(type);
  };

  const updateSnippetLanguage = (language) => {
    setSnippetLanguage(language);
  };

  const updateClipboardText = () => {
    if (copyToClipboardText === 'Copy to Clipboard') {
      setCopyToClipboardText('Copied!');
      setTimeout(() => {
        setCopyToClipboardText('Copy to Clipboard');
      }, 1000);
    }
  };

  const prevThumbnail = () => {
    const nextThumbnail = currentThumbnail - 1;
    setCurrentThumbnail(
      nextThumbnail < 0 ? size(thumbnails) - 1 : nextThumbnail
    );
  };

  const nextThumbnail = () => {
    const nextThumbnail = currentThumbnail + 1;
    setCurrentThumbnail(
      nextThumbnail > size(thumbnails) - 1 ? 0 : nextThumbnail
    );
  };

  if (props.submit) {
    useEffect(() => {
      generateFilePreview();
    }, []);
  }

  return (
    <div className="sandbox">
      <Controls
        fileUrl={fileUrl}
        sandboxId={props.sandboxId}
        filePickerKey={props.filePickerKey}
        previewOptions={previewOptions}
        updateOptions={updateOptions}
        updateFileUrl={updateFileUrl}
        generateFilePreview={generateFilePreview}
      />
      <Preview
        thumbnails={thumbnails}
        previewData={previewData}
        previewColor={previewColor}
        currentThumbnail={currentThumbnail}
        isGeneratingPreview={isGeneratingPreview}
        prevThumbnail={prevThumbnail}
        nextThumbnail={nextThumbnail}
        updatePreviewBackground={updatePreviewBackground}
      />
      <Snippet
        fileUrl={fileUrl}
        clientKey={props.clientKey}
        serverKey={props.serverKey}
        previewData={previewData}
        snippetType={snippetType}
        previewOptions={previewOptions}
        snippetLanguage={snippetLanguage}
        serverSecretKey={props.serverSecretKey}
        isGeneratingPreview={isGeneratingPreview}
        copyToClipboardText={copyToClipboardText}
        updateSnippetType={updateSnippetType}
        updateClipboardText={updateClipboardText}
        updateSnippetLanguage={updateSnippetLanguage}
      />
    </div>
  );
}
