import './styles/index.css';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { parseInt } from 'lodash';
import { Sandbox } from './views/Sandbox';

const $boxes = document.querySelectorAll('.fp-sandbox');

Array.prototype.forEach.call($boxes, ($box, index) => {
  const sandboxId = `fp-sandbox-${index}`;

  const pages = $box.getAttribute('data-pages');
  const width = $box.getAttribute('data-size-width');
  const height = $box.getAttribute('data-size-height');
  const format = $box.getAttribute('data-format');
  const fileUrl = $box.getAttribute('data-file-url');
  const metadata = $box.getAttribute('data-metadata');
  const token = $box.getAttribute('data-token');
  const submit = $box.getAttribute('data-submit');

  const serverKey = 'XXXX-YOUR-SERVER-KEY-HERE-XXXX';
  const serverSecretKey = 'XXXX-YOUR-SERVER-SECRET-KEY-HERE-XXXX';

  const props = {
    fileUrl,
    sandboxId,
    token: token || null,
    serverKey,
    serverSecretKey,
    submit: submit === 'true',

    previewOptions: {
      pages: pages || 'all',
      format: format || 'png',
      metadata: metadata ? metadata.split(',') : [],

      size:
        width && height
          ? {
              width: parseInt(width),
              height: parseInt(height),
            }
          : {
              width: null,
              height: null,
            },
    },
  };

  const root = createRoot($box);
  root.render(<Sandbox id={sandboxId} {...props} />);
});
